export const allowlistAddresses = [
    
  ["0xf511cfab8f2dbedeed91afa8a3fc6246c18d4ee4",2870],
  ["0x45de1c3b41596224c51c66fc286aa5b363511dcb",3510],
  ["0x7e45c617350395ba24210c3b0f926f9973177b9f",3533],
  ["0x48ef11011ded807f3246bb8c2f4ce9426e09be88",3127],
  ["0x82e1e9666edac4eb98850ce2ef2cf9bfedf2183d",3442],
  ["0xbab1a10d74da9943b593c3eadf95cd41b968a991",2830],
  ["0xf308cc36bd85f135b5e65c8b8ede781b1cbed7bc",749],
  ["0x6112b496e43a05e61797743a45640df13efcf053",3662],
  ["0x8422075cf6daf10316ab867ce6b4db3d47d0458b",3162],
  ["0x95debaeb0c9eafe11fa5c7c96ab64dd4e436d769",2904],
  ["0x610d8db1a96ecc513b588fc5ca514e33512c91a8",2425],
  ["0xa909427988bb1ea4924b522eddebb9b6fb29a2f1",2361],
  ["0x91efd32e53253cc56468981c1d13361ad0d5cb8d",3589],
  ["0x1a3326bf12589ee53b3f796c9d376ea3df60d459",3457],
  ["0x87369e67068197d9a164884c4541bd1503ccfb8f",2574],
  ["0x1c7f1b7650d505a1a10d25b05d0f7b9cbd41c5f7",2884],
  ["0xa17cc03654e43576fd890c3aeeb3670fb70dd540",2676],
  ["0x3342e89ec9e8560c2892c331909da2fe56f20f59",3148],
  ["0x5c3d97f9a3b7938ca043b3a671b4b05291a94823",2517],
  ["0x9f56bff4459a58488d76df9e42a1c8fe48046f7d",2524],
  ["0x69e9ec83cba73744fa44f4183e200e038320f4dc",2876],
  ["0x75c4d7544dc881596e3679345a7e915f62171dea",3687],
  ["0xdeb3f6f80e1f9fc698990171a48578ecd2e42aef",3579],
  ["0xd0d5250a553d07f6622b19b04929f64e7876db93",3597],
  ["0xf1ea9b0a22ac3494cb74bbc1bb985cf3f091992b",3337],
  ["0xd1b74e02e51ff3c92c7ba53f893f8c9828071d77",2672],
  ["0xac75248edcd608878324c6894427977f8ef8599d",2549],
  ["0xa1a81412ae054e1f9c82586bbf2fe6aa39d256e4",3046],
  ["0x44a44daabf903bf6b30a75b15e1b7772648c1529",3678],
  ["0x3d663a4f09a00541ee7bb5094c8f56c7fa91a6e8",3578],
  ["0x67009159dd4feed5ec9e573d55912b41ec2d7615",2970],
  ["0x87481851f2cacfff8763f49bff57e7afe3ab87dc",3310],
  ["0x6313fdbc70088df6474f00aec0ea38413351e32b",3238],
  ["0xf78ba828973c5406924342f1d6865d2214cd3d6e",3582],
  ["0x03eeba1287dbbc6e5a10e3c916976a27ad00fe29",3339]

]